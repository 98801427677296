@import url("https://fonts.googleapis.com/css2?family=Francois+One&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	color: #212121;
}

button {
	border: none;
	color: white;
	background: transparent;
	cursor: pointer;
}

a {
	text-decoration: none;
}

body {
	background-color: #f7f3e5;
}

span {
	display: flex;
	gap: 10px;
}

p {
	font-size: 16px;
}

.cardStyle {
	border-radius: 25px;
	margin: 50px auto;
	text-align: center;
	/* margin-top: 50px; */
	padding: 50px;
	gap: 15px;
	/* margin: 1rem; */
	max-width: 800px;
	align-items: center;
}

.yellowCard img {
	width: 100%;
	object-fit: cover;
	border-radius: 25px;
}

.greenCard {
	background-color: #badc58;
}

.yellowCard {
	background-color: #f6d253;
}

.blueButton {
	background-color: #282e65;
	width: 200px;
	border: none;
	padding: 10px;
	margin: 0 auto;
	color: #eefef1;
	border-radius: 0.5rem;
	font-size: 2.5rem;
	/* margin-top: 60px; */
	cursor: pointer;
}

a:hover {
	text-decoration: underline;
}

.blueButton a {
	text-decoration: none;
	color: #eefef1;
}

.blueButton:hover {
	background-color: #0074a9;
}

.blueButton a:hover,
.greenButton a:hover {
	text-decoration: none;
}

.greenButton {
	background-color: #badc58;
	width: 220px;
	border-radius: 2rem;
	font-size: 2.5rem;
	padding: 10px;
	margin: 0 auto;
	font-family: "Francois One", sans-serif;
	cursor: pointer;
	color: black;

	border: 1px solid #082d15;
}

.greenButton:hover {
	background-color: #39b665;
}

strong {
	font-weight: 700;
}

.line {
	display: block;
	height: 2px;
	background-color: #000;
	margin: 0 auto;
	width: 90%;
}

h2 {
	font-weight: bold;
	font-size: 1.5rem;
}

.modalBackground {
	position: "absolute";
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: "rgba(0, 0, 0, 0.15)";
}

.modal {
	position: absolute;
	background: white;
	top: 25;
	left: 10%;
	right: 10%;
	padding: 1rem;
}

.lightLine {
	height: 2px;
	width: 100%;
	background-color: #f3de96;
}
