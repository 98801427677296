.contest {
	padding: 2rem;
	min-width: 540px;
}

.contest .innerCard h2 {
	margin-bottom: 0.25rem;
	font-size: 1.4rem;
	font-weight: 500;
	text-align: left;
}

.contest .innerCard p,
.contest .innerCard a {
	font-size: 1.125rem;
	font-weight: 300;
	margin-bottom: 1.25rem;
	text-align: left;
}

#scratchContainer {
	width: auto;
	height: auto;
	display: flex;
	justify-content: center;
	margin-bottom: 2rem;
}

#scratchCard {
	/* margin: 2rem; */
	flex: 1 1 100%;

	border-radius: 0.6em;
	min-height: 440px;
	max-height: 700px;
	max-width: 460px;
	position: relative;
	min-width: 300px;
	width: fit-content;
	/* height: 700px; */
	/* width: 460px; */
	/* min-height: 400px;
	max-height: 600px;
	max-width: 800px; */
	/* overflow: hidden; */
}

.base img {
	text-align: center;
	width: 100%;
	height: 100%;
	/* object-fit: contain; */

	border-radius: 1rem;
}

#scratch {
	top: 41%;
	left: 17%;
	/* top: 300px; */
	/* left: 80px; */
	position: absolute;
	cursor: grabbing;
	border-radius: 0.5rem;
}

.contest button {
	width: auto;
}

.base {
	font-family: "Poppins", sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.base h3 {
	font-weight: 600;
	font-size: 1.5em;
	color: #17013b;
}

.base h4 {
	font-weight: 400;
	color: #746e7e;
}

#scratch {
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	user-select: none;
}
